'use client';

import { Button } from '@/components/ui/button';
import { Check } from 'lucide-react';
import Link from 'next/link';
import NumberOne from '@components/Badge/NumberOne';
import { useSession } from '@hooks/index';

import React from 'react';

import { ExampleMarquee } from '@/components/Marketing/Marquees/example-marquee';
import { GetYourPortraitsButton } from '@components/Button/get-your-portraits';
import { LogoCloud } from '@/components/Marketing/LogoCloud/LogoCloud';

export default function ProHeadshotsNoPhotographer() {
  const { isLoggedIn } = useSession();

  return (
    <section className="bg-gradient-to-b from-black via-black to-[#251C27] overflow-hidden py-10 md:py-20 relative isolate z-0">
      <div
        className="absolute inset-x-0 -top-40 -z-50 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'
          }}
        />
      </div>
      <div className="container px-10 mx-auto">
        <div className="bg-red-500/0 mx-auto flex flex-col lg:flex-row gap-6 lg:gap-12 items-start max-w-screen-lg h-fit">
          <div className="bg-red-500/0 w-full lg:w-1/2 text-balance space-y-4 ">
            <h1 className="text-4xl md:text-6xl font-bold text-white items-center flex gap-2 text-left w-fit">
              Pro Headshots.
              <br />
              No Photographer.
            </h1>
            <div className="text-white text-xl">
              <ul className="space-y-2 pt-2 pb-4 ">
                <li className="flex items-center">
                  <Check className="w-4 h-4 mr-2 text-green-300" />
                  AI Photos that look 100% real
                </li>
                <li className="flex items-center">
                  <Check className="w-4 h-4 mr-2 text-green-300" />
                  Delivered in minutes{' '}
                </li>
                <li className="flex items-center">
                  <Check className="w-4 h-4 mr-2 text-green-300" />
                  Invoice suitable for business expenses
                </li>
              </ul>
            </div>
          </div>
          {/* COLUMN TWO */}
          <div className="bg-red-500/0 flex flex-col gap-4 w-full lg:w-1/2  mt-auto">
            <div className="flex flex-col gap-4 w-full">
              {!isLoggedIn ? (
                <>
                  <div className="flex flex-col gap-4 w-full">
                    <div className=" bg-red-500/0 h-fit lg:h-full flex justify-center gap-6 items-center text-sm text-muted-foreground py-4 px-6">
                      <div className="bg-red-500/0 flex flex-col gap-2 w-1/2 justify-center">
                        <div className="flex items-center  invert dark:invert-0 mx-auto">
                          <NumberOne />
                        </div>
                      </div>
                      <div className="bg-red-500/0 flex flex-col gap-2 w-1/2 justify-center items-center">
                        <div className="flex -space-x-2 overflow-hidden">
                          {[
                            'https://storage.prompt-hunt.workers.dev/0fbbc50f-f824-49da-8f33-86c23cfa1cdc',
                            'https://storage.prompt-hunt.workers.dev/0e215b6b-3b00-4ba1-835a-309ec76cf538',
                            'https://storage.prompt-hunt.workers.dev/4e4ddd7d-e997-48fd-9182-7048db2262ae',
                            'https://storage.prompt-hunt.workers.dev/d8ae8f9a-9f34-47b7-beb1-a8d455e63c34'
                          ].map((src, index) => (
                            <img
                              key={index}
                              className="inline-block h-6 w-6 rounded-full ring-2 ring-black"
                              src={src}
                              alt=""
                            />
                          ))}
                        </div>
                        <div className="text-sm whitespace-nowrap">86,000+ customers</div>
                      </div>
                    </div>
                    <GetYourPortraitsButton source="main_hero_cta" />
                  </div>
                </>
              ) : (
                <Button className="w-full" variant={'accent'} size="xl" asChild>
                  <Link href="/dashboard">Go to dashboard</Link>
                </Button>
              )}
            </div>
          </div>
        </div>

        <ExampleMarquee className="mt-10  -mx-10 lg:mx-auto" />
      </div>

      <LogoCloud className="mt-10 invert" />
    </section>
  );
}
