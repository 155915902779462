// pages/marketing.js
import AiGeneratedBadge from '@components/Badge/ai-generated-badge';
import { Button } from '@components/ui/button';
import Image from 'next/image';
import Link from 'next/link';
import { GetYourPortraitsButton } from '@components/Button/get-your-portraits';
export default function PhotoGallery() {
  const images = [
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/1f3f8dfb-e666-46c8-5a71-592560be5700/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/cdee6b2f-78d1-461f-f908-7be9710ac300/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/3d58ac72-ba0f-4556-8630-ed8f49ebe600/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/c445a15b-9d25-4c6a-356f-3bcd95657000/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/2fc6d65f-052d-4c7a-4b86-a08a834be500/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/a2e5a630-4e7f-4802-493f-d9bc32aaac00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/d4412e29-c982-41bf-587b-7362c5518400/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/c5f23bf0-aa74-44d8-36cd-06b0eedfde00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/1183d732-55e1-4625-3803-edbb66c2d600/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/64fe9629-1b3a-4b6d-8607-742f0245e400/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/0dc76e89-90d7-45ce-37e7-6d8b18482300/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/486e435e-4abf-4b41-e4cb-2979c78c7700/public'
  ];

  return (
    <>
      <div className="mx-auto max-w-2xl sm:text-center mb-6">
        <h3 className="text-3xl md:text-4xl font-bold tracking-tight">Real Users, Real Results with PicStudio.AI</h3>
        <p className="text-lg leading-8 text-muted-foreground">
          Quick and effortless professional headshots, ready for anywhere.
        </p>
      </div>
      <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {images.map((image, index) => (
          <div className="aspect-[5/4] overflow-hidden w-full rounded-lg relative" key={index}>
            <AiGeneratedBadge className="absolute bottom-3 left-1/2 -translate-x-1/2" />
            <Image src={image} alt="Generated Image" width={1000} height={1000} className="rounded-lg shadow-lg" />
          </div>
        ))}
      </div>
      <div className="flex flex-row gap-4 w-full mx-auto max-w-screen-sm my-10">
        <Button variant="outline" className="flex-1">
          <Link href="examples-and-reviews">More examples</Link>
        </Button>
        <GetYourPortraitsButton source="photo_gallery" className="flex-1" size="default" />
      </div>
    </>
  );
}
