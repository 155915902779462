import React from 'react';
import Image from 'next/image';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@components/ui/card';
import { RiInstagramFill } from 'react-icons/ri';
import { FaLinkedin, FaYoutube } from 'react-icons/fa';
import { Bookmark, Heart, MessageCircle, Send } from 'lucide-react';
import { Avatar } from '@components/ui/avatar';
import { AvatarImage } from '@radix-ui/react-avatar';

export default function Usecases() {
  return (
    <div className="bg-background relative isolate overflow-hidden  mb-6">
      <div className="mx-auto">
        <div className="mb-10 text-3xl xl:text-5xl space-y-4">
          <p className="font-semibold tracking-tight leading-tight" style={{ marginBlockStart: 0 }}>
            AI Photos that look{' '}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
              100% real.
            </span>
          </p>
          <p className="text-3xl text-muted-foreground" style={{ marginBlockStart: 0 }}>
            This is how folks use PicStudio.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-end">
          <Card className="ring-white/10 border-none ring-[1px] ring-inset relative text-white bg-neutral-600 dark:bg-card">
            {' '}
            <div className="relative z-10">
              <CardHeader>
                {' '}
                <CardTitle className="mb-2">
                  <div className="flex flex-row justify-start items-center gap-3">
                    <FaYoutube />
                    Youtube
                  </div>
                </CardTitle>
                <CardDescription className=" text-white/80">
                  Boost click-through rates and improve video performance by generating captivating portraits for your
                  thumbnails.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Card>
                  <Card className="bg-white text-black">
                    <div className="flex flex-row justify-start items-center gap-2 p-2">
                      <Avatar className="w-8 h-8">
                        <AvatarImage
                          src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/5d15ff0d-985e-4e4d-3e60-23a9fc4f6c00/public"
                          alt="Instagram Avatar"
                          className="object-cover"
                        />
                      </Avatar>
                      <div className="text-xs font-medium">
                        <div>Greg Isenberg</div>
                      </div>
                    </div>
                    <Image
                      src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/44ccb1d8-7aed-4ea4-2d30-b5b0185e1700/public"
                      height={1000}
                      width={1000}
                      alt="Instagram Example"
                    />
                    <div className="p-4 gap-3 space-y-0 text-xs">
                      {/* <Skeleton className="h-3 w-3/4 bg-black/20 animate-none" />
                    <Skeleton className="h-3 w-1/2 bg-black/20 animate-none" /> */}
                      <div className="font-medium">I can&apos;t believe we coded an app with AI in 67 mins</div>
                      <div className="opacity-60">50k views</div>
                    </div>
                  </Card>
                </Card>
              </CardContent>
            </div>
            <div
              className="absolute top-0 right-0 bottom-0 left-0 z-0 opacity-20"
              style={{
                backgroundColor: 'hsla(0,0%,0%,1)',
                backgroundImage: `
                radial-gradient(at 83% 95%, hsla(0,100%,40%,1) 0px, transparent 50%),
                radial-gradient(at 14% 23%, hsla(0,100%,75%,1) 0px, transparent 50%),
                radial-gradient(at 9% 10%, hsla(0,100%,19%,1) 0px, transparent 50%),
                radial-gradient(at 52% 75%, hsla(0,100%,50%,1) 0px, transparent 50%)`
              }}
            />
          </Card>

          <Card className="ring-white/10 border-none ring-[1px] ring-inset relative h-fit text-white">
            <div className="z-10 relative">
              <CardHeader>
                {' '}
                <CardTitle className="mb-2">
                  <div className="flex flex-row justify-start items-center gap-3">
                    <FaLinkedin />
                    LinkedIn{' '}
                  </div>
                </CardTitle>
                <CardDescription className=" text-white/80">
                  Make a strong first impression by generating polished portraits that elevate your LinkedIn profile.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Card className="bg-white text-black">
                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/8a2f7054-36c7-40db-c5a4-114e9657ed00/public"
                    alt={''}
                    height="1000"
                    width="1000"
                  />
                  <div className="flex flex-row justify-start items-center gap-2 p-4">
                    <Avatar className="-mt-16 w-24 h-24 border-4 border-white">
                      <AvatarImage
                        src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/6f36fed3-32af-431e-b32e-922f258b2b00/public"
                        alt="Linkedin Avatar"
                        className="object-cover bg-white"
                      />
                    </Avatar>
                  </div>
                  <div className="px-4 font-medium">
                    <div>Kayleigh S.</div>
                    <div className="opacity-60">Talent Advisor- Lead Tech Recruiter</div>
                  </div>
                  <div className="p-4 gap-3 space-y-3 text-xs"></div>
                  <div className="h-12 px-4">
                    <div className="w-full h-12 bg-slate-300" />
                  </div>
                </Card>
              </CardContent>
            </div>

            <div
              className="absolute top-0 right-0 bottom-0 left-0 z-0 dark:opacity-20"
              style={{
                backgroundColor: 'hsla(210,90%,40%,1)',
                backgroundImage:
                  'radial-gradient(at 46% 71%, hsla(212,55%,58%,1) 0px, transparent 50%),' +
                  'radial-gradient(at 66% 91%, hsla(227,38%,66%,1) 0px, transparent 50%),' +
                  'radial-gradient(at 0% 100%, hsla(213,18%,56%,1) 0px, transparent 50%),' +
                  'radial-gradient(at 80% 100%, hsla(211,25%,70%,1) 0px, transparent 50%)'
              }}
            />
          </Card>
          <Card className="ring-white/10 border-none ring-[1px] ring-inset relative text-white">
            <div className="z-10 relative">
              <CardHeader className="">
                {' '}
                <CardTitle className="mb-2">
                  <div className="flex flex-row justify-start items-center gap-3">
                    <RiInstagramFill />
                    Instagram
                  </div>
                </CardTitle>
                <CardDescription className=" text-white/80">
                  Enhance personal branding and engagement by producing stunning, high-quality portraits for Instagram.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Card className="bg-white text-black">
                  <div className="flex flex-row justify-start items-center gap-2 p-2">
                    <Avatar className="w-8 h-8">
                      <AvatarImage
                        src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/f76525f5-d0da-4406-2006-6b7e7d127900/512"
                        alt="Instagram Avatar"
                        className="object-cover"
                      />
                    </Avatar>
                    <div className="text-xs font-medium">
                      <div>Nathaniel Taylor</div>
                    </div>
                  </div>
                  <Image
                    src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/9287bb9c-ffa3-4139-1085-07210578b400/public"
                    height={1000}
                    width={1000}
                    alt="Instagram Example"
                  />
                  <div className="flex flex-row p-2 gap-3">
                    <Heart />
                    <MessageCircle />
                    <Send />
                    <Bookmark className="ml-auto" />
                  </div>
                </Card>
              </CardContent>
            </div>

            <div
              className="absolute top-0 right-0 bottom-0 left-0 z-0 dark:opacity-10"
              style={{
                backgroundColor: '#b699ff',
                backgroundImage: `
                radial-gradient(at 9% 10%, hsla(355,81%,62%,1) 0px, transparent 50%),
                radial-gradient(at 26% 30%, hsla(201,90%,68%,1) 0px, transparent 50%),
                radial-gradient(at 16% 28%, hsla(23,83%,62%,1) 0px, transparent 50%),
                radial-gradient(at 39% 91%, hsla(301,67%,63%,1) 0px, transparent 50%),
                radial-gradient(at 60% 52%, hsla(29,61%,68%,1) 0px, transparent 50%),
                radial-gradient(at 45% 66%, hsla(225,79%,61%,1) 0px, transparent 50%),
                radial-gradient(at 52% 75%, hsla(202,76%,64%,1) 0px, transparent 50%)`
              }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}
