import Image from 'next/image';
import Link from 'next/link';
import { Button } from '@components/ui/button';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '@components/ui/card';
import { Star } from 'lucide-react';

const testimonials = [
  {
    body: 'OMG. This is so cool. I love it.',
    author: {
      name: 'Kayleigh S.',
      handle: 'kleesmif',
      avatarUrl: 'https://storage.prompt-hunt.workers.dev/0fbbc50f-f824-49da-8f33-86c23cfa1cdc'
    }
  },
  {
    body: "It's sort of shocking how good some of the portraits came out. All you have to do is upload 8 half-decent selfies.",
    author: {
      name: 'Daniel',
      handle: 'daniel',
      avatarUrl:
        'https://media.licdn.com/dms/image/v2/D4E03AQE6kYbhMGyGkA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1726127274977?e=1733356800&v=beta&t=9CzccXVbJ3GaXgE8F8xCGwpmOY6HoGndhR7NrfEOMsM'
    }
  }
];

export default function Examples() {
  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Skeptical? Take a look.</CardTitle>
        <CardDescription>Here are some examples of what PicStudio.AI can do.</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="mx-auto flex flex-col gap-4 justify-center items-center w-full">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="max-w-xs w-full">
              <div className="bg-muted dark:bg-white/10 rounded-2xl rounded-bl-sm p-4 text-foreground">
                <p className="text-sm mb-2">{testimonial.body}</p>
                <div className="flex justify-start">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="w-4 h-4 fill-current text-yellow-400" />
                  ))}
                </div>
                <figcaption className="flex items-center gap-x-2 mt-2">
                  <Image
                    className="h-6 w-6 rounded-full bg-secondary/10"
                    src={testimonial.author.avatarUrl}
                    alt={testimonial.author.name}
                    width={24}
                    height={24}
                  />
                  <div className="space-y-0">
                    <div className="font-semibold text-primary">{testimonial.author.name}</div>
                  </div>
                </figcaption>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
      <CardFooter>
        <Button size={'sm'} variant={'secondary'} asChild>
          <Link href="/examples-and-reviews" className="">
            View examples of reviews
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
}
