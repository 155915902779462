'use client';

import React from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardFooter } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { cn } from '@lib/utils';
import Image from 'next/image';
import Link from 'next/link';
interface AffiliateProgramCardProps {
  className?: string;
}

export default function AffiliateProgramCard({ className }: AffiliateProgramCardProps) {
  return (
    <Card className={cn('overflow-hidden', className)}>
      <div className="relative pt-6 px-12">
        <Image
          src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/3c6ffb53-a50f-4359-1e92-6b811b612f00/optimized"
          alt="Affiliate program"
          width={960}
          height={540}
          className="mx-auto aspect-video object-cover max-h-[512px] max-w-[512px] w-full"
        />
      </div>
      <CardHeader className="max-w-[512px]">
        <CardTitle className="text-lg font-bold">Earn $5 for every friend</CardTitle>
        <CardDescription>
          Join our affiliate program and earn a commission for every sale you generate on PicStudio.
        </CardDescription>
      </CardHeader>
      <CardFooter>
        <Button size="sm" variant="secondary">
          <Link href="/refer">Learn more</Link>
        </Button>
      </CardFooter>
    </Card>
  );
}
