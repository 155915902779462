import { Card } from '@components/ui/card';
import Image from 'next/image';

const features = [
  {
    title:
      '<span class="text-foreground">Uncanny resemblance</span> <span class="opacity-60">that captures everything unique about you</span>',
    imageUrl: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/779e09ff-fb8b-4f1b-3c66-87cc37f65700/public'
  },
  {
    title:
      '<span class="text-foreground">Hands done right</span> <span class="opacity-60">with natural and precise details</span>',
    imageUrl: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/4689b946-f2f9-4dff-f6df-5c49c0cb4100/public'
  },
  {
    title:
      '<span class="text-foreground">Realistic skin</span> <span class="opacity-60">capturing every pore and texture perfectly</span>',
    imageUrl: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/6e507c55-77bd-497c-2521-a2fe1f284b00/public'
  },
  {
    title:
      '<span class="text-foreground">Professionally upscaled</span> <span class="opacity-60">high-resolution images for printing</span>',
    imageUrl: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/909104fa-30d3-4cf2-da6c-22dcdab0e700/public'
  }
];

export default function Quality() {
  return (
    <div className="flex flex-col gap-4 rounded-xl lg:rounded-2xl overflow-hidden">
      {/* <h2 className="text-3xl font-semibold tracking-tight text-primary mb-1 text-center">
        Photos that look 100% real.
      </h2> */}
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {features.map((feature, index) => (
          <Card key={index} className="p-6">
            <div className="flex-1">
              <div className="w-full aspect-[4/3] overflow-clip bg-muted rounded-lg mb-4 flex items-center justify-center">
                <Image src={feature.imageUrl} alt="" height={1000} width={10000} />
              </div>
              <h3
                className="text-sm md:text-lg font-semibold text-foreground"
                dangerouslySetInnerHTML={{ __html: feature.title }}
              ></h3>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}
