'use client';

import Image from 'next/image';
import { Marquee } from '@/components/marquee/marquee';

const imageUrls = [
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/cab2b767-227b-4bf3-bbc0-9fb6f52cac00/1024', //sam
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/25fc2ea9-b972-4604-1b41-683f6f2de600/1024', //addison
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/21098c07-b89a-454c-7a5c-410e806dd400/1024', //isaac
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/3b440698-b65c-4a55-90b4-75cfff55b400/1024', //marcela
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/e80c0a35-f745-420a-593d-692e8072d700/1024', //geo
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/bdd6eeeb-9e28-434e-16d9-e41396052c00/1024', //nate
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/fa6c7b83-73be-454b-03ad-32325821a700/1024', //kayleigh
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/1a011076-0ccd-4e80-b4dc-95e5ed45dd00/1024', //tim
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/083cc560-2a82-470b-1cd1-35c35b1dac00/1024', //philly
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/e4d382c6-3f17-4238-bf26-9455f22f7600/1024', //user1
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/0653e80e-96a0-45c6-8bff-433a7fb5f800/1024', //user2
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/77ba2fd2-8649-4ebf-3a6c-1c934088e900/1024', //adam
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/b46b9868-cc49-4465-7c60-009cb07ea100/1024' //gretchen
];

// const imageDimensions = {
//   width: 883,
//   height: 508
// };

interface ExampleMarqueeProps {
  className?: string;
}

export function ExampleMarquee({ className }: ExampleMarqueeProps) {
  return (
    <Marquee className={className} pauseOnHover repeat={2}>
      {imageUrls.map((url, index) => (
        <div key={index} className="relative w-[200px] md:w-[300px] h-[115px] md:h-[173px] mx-0">
          <Image
            src={url}
            alt={`Marquee image ${index + 1}`}
            fill
            className="object-cover rounded-lg"
            sizes="(max-width: 200px) 100vw, 200px"
            priority={index < 4} // Load first 4 images with priority
          />
        </div>
      ))}
    </Marquee>
  );
}
