import React from 'react';
import Link from 'next/link';
import { Button } from '@components/ui/button';
import Image from 'next/image';
import { cn } from '@lib/utils';
interface PackTheme {
  name: string;
  slug: string;
  imageUrl: string;
}

interface PacksProps {
  className?: string;
  customThemes?: PackTheme[];
}

const defaultThemes: PackTheme[] = [
  {
    name: 'Founder Pro',
    slug: 'founder-pro',
    imageUrl: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/1ff7f0b7-8f58-45db-40ec-3378ded73800/public'
  },
  {
    name: 'Dating Profile',
    slug: 'dating-profile',
    imageUrl: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/5d13f864-567c-463b-5716-fd4a8082ac00/public'
  },
  {
    name: 'Wired Portrait',
    slug: 'wired-portrait',
    imageUrl: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/94d99780-5f1f-4586-9fd7-ec48e1435400/public'
  },
  {
    name: 'Casual Colors',
    slug: 'casual-colors',
    imageUrl: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/47c14196-9e65-497e-0fbc-1ec236898800/public'
  },
  {
    name: 'Office Pro',
    slug: 'office-pro',
    imageUrl: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/70a3e2ae-7276-4df9-6a2f-dfdbcde68400/public'
  }
];

export default function Packs({ customThemes, className }: PacksProps) {
  const themesToShow = customThemes || defaultThemes;

  return (
    <div className={cn('relative bg-purple-500/0 justify-center flex flex-col items-center', className)}>
      <div className="">
        <div className="mx-auto max-w-2xl text-center items-center justify-center flex flex-col mb-10">
          <h2 className="text-4xl font-bold tracking-tight text-studio-900 sm:text-6xl">Hundreds of styles</h2>
          <p className="mt-6 text-lg leading-8 text-studio-600 max-w-lg w-full mb-10">
            Get photos in a variety of styles and themes. Choose from our selection of packs to get started.
          </p>
          <div className="flex items-center justify-center gap-x-6">
            <Button
              className=""
              variant={'accent'}
              size={'xl'}
              asChild
              data-element-location="packs_section"
              data-cta-type="accent"
              data-element-text="Browse packs"
            >
              <Link href="/packs/browse">Browse photo packs</Link>
            </Button>
          </div>
        </div>
      </div>
      <div className="px-6 lg:px-8 md:-mx-32 lg:-mx-48 xl:-mx-64 overflow-x-auto w-full">
        <div className="grid grid-cols-3 sm:grid-cols-5 gap-1 md:gap-4 bg-red-500/0 pb-16">
          {themesToShow.map((theme, index) => (
            <div
              key={theme.slug}
              className={`${
                index === 0 || index === 4
                  ? 'pt-0 hidden sm:block'
                  : index === 1 || index === 3
                  ? 'pt-[30%]'
                  : 'pt-[15%]'
              } ${index === 0 ? 'pb-8' : ''}`}
            >
              <Link href={`/packs/browse/${theme.slug}`}>
                <Image
                  alt={theme.name}
                  src={theme.imageUrl}
                  width={1000}
                  height={1000}
                  className="col-span-1 rounded-xl md:rounded-3xl shadow-[rgba(0,_0,_0,_0.3)_0px_16px_16px_-7px]"
                />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
