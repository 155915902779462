'use client';

import React from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardFooter } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { cn } from '@lib/utils';
import Link from 'next/link';
import { Marquee } from '@components/marquee/marquee';
import Image from 'next/image';
const imageUrls = [
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/86a3a374-8764-441f-cade-a8c2bb16aa00/public',
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/d9b24282-09eb-49ed-f51c-332ae4eac200/public',
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/70a3e2ae-7276-4df9-6a2f-dfdbcde68400/public',
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/94d99780-5f1f-4586-9fd7-ec48e1435400/public',
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/f942eac6-eacf-4b39-44d0-46798f2e6900/public',
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/112f4912-c7f6-4961-163f-baa18f7c6a00/public',
  'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/5d13f864-567c-463b-5716-fd4a8082ac00/public'
];

interface BrowsePhotoPacksCardProps {
  className?: string;
}

export default function BrowsePhotoPacksCard({ className }: BrowsePhotoPacksCardProps) {
  return (
    <Card className={cn('overflow-hidden', className)}>
      <CardHeader className="max-w-[512px]">
        <CardTitle className="text-lg font-bold">Explore our photo packs</CardTitle>
        <CardDescription>
          With hundreds of styles to choose from, you can get your next headshot with AI.
        </CardDescription>
      </CardHeader>
      <CardFooter>
        <Button size="sm" variant="secondary">
          <Link href="/packs/browse">Browse all styles</Link>
        </Button>
      </CardFooter>
      <div className="relative h-full">
        <Marquee>
          {imageUrls.map((url, index) => (
            <div key={index} className="relative w-[100px] md:w-[125px] h-auto mx-0 aspect-[4/5]">
              <Image
                src={url}
                alt={`Marquee image ${index + 1}`}
                fill
                className="object-cover rounded-lg"
                sizes="(max-width: 200px) 100vw, 200px"
                priority={index < 4} // Load first 4 images with priority
              />
            </div>
          ))}
        </Marquee>
      </div>
    </Card>
  );
}
